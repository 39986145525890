import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {version} from '../package.json'
import {ApolloClient} from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.config.productionTip = false

const apiHttpLink = createHttpLink({
  uri: "https://api.gis.msu.edu/graphql",
});
const apiCache = new InMemoryCache({
  freezeResults: true,
});
const apolloClient = new ApolloClient({
  link: apiHttpLink,
  cache: apiCache,
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.use(VueApollo);

Vue.use(VueGtm, {
  id: "GTM-P6CFBNWXN8",
  defer: false,
  debug: true,
  vueRouter: router,
  loadScript: false,
  trackOnNextTick: false,
});


new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  created() {
    window.addEventListener('beforeunload', this.beforeExit)
  },
  methods: {
    beforeExit: function (event) {
      console.log('Storing state at app exit, state.version=', this.$store.state.version)
      if (this.$store.state.version !== version) {
        this.$store.state.version = version
      }
      localStorage.setItem('edu.msu.campusaddresses', JSON.stringify(this.$store.state));
    }
  },
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  console.log('Storing state', state)
  localStorage.setItem('edu.msu.campusaddresses', JSON.stringify(state));
});
