<template>
  <v-app id="campus-addresses">
    <v-app-bar
        app
        color="white"
        extended
        flat
        extension-height="75"
    >
      <v-container class="py-0 fill-height">
        <v-app-bar-title>
          <v-img
              alt="MSU Logo"
              class="shrink mr-2"
              contain
              height="40"
              src="./assets/masthead-helmet-black.svg"
          />
          <h3>
            CAMPUS ADDRESSES
          </h3>
        </v-app-bar-title>
      </v-container>

      <template v-slot:extension>
        <v-container style="margin-top: 15px;">
          <hr style="width: 100%">
          <v-tabs align-with-title show-arrows>
            <v-tab
                v-for="route in routes"
                :key="route.name"
                :to="route.path"
                class="subheading mr-0"
            >
              {{ route.meta.title }}
            </v-tab>
            <v-menu
                bottom
                left
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    class="align-self-center subheading mx-3 text--lighten-5"
                    v-bind="attrs"
                    v-on="on"
                    color="rgba(0,0,0,.54)"
                >
                  ADDITIONAL RESOURCES
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list class="white darken-2">
                <v-list-item
                    v-for="item in additionalResources"
                    :key="item.title"
                    :href="item.url"
                    link
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>
          <hr style="width: 100%">
        </v-container>
      </template>

    </v-app-bar>

    <v-main app>
      <v-container class="fill-height">
        <transition name="fade" appear>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </v-container>
    </v-main>

    <v-footer class="hidden-md-and-down" app color="#ffffff">
      <v-container>
        <hr style="width: 100%"/>
        <div class="row">
          <div class="col-lg-11">

            <div id="MSUstandardFooter" class="MSUwrapper light-background">
              <div class="fourMSUcolumns" id="standard-footer-MSU-wordmark">
                <a href="http://www.msu.edu">
                  <img class="screen-msuwordmark" alt="Michigan State University Wordmark"
                       src="./assets/msu-wordmark-green-221x47.png"/>
                  <img class="print-msuwordmark" alt="Michigan State University Wordmark"
                       src="./assets/msu-wordmark-black-221x47.png"/>
                </a>
              </div>

              <div class="twelveMSUcolumns">
                <div id="standard-footer-site-links">
                  <ul>
                    <li>Call us: <strong>(517) 355-1855</strong></li>
                    <li>
                      <router-link to="/about/contact/" accesskey="9">Contact Information</router-link>
                    </li>
                    <li>
                      <router-link to="/about/privacy-policy/" accesskey="7">Privacy Policy</router-link>
                    </li>
                    <li>
                      <router-link to="/about/accessibility/" accesskey="8">Site Accessibility</router-link>
                    </li>
                  </ul>
                </div>

                <div id="standard-footer-MSU-info">
                  <ul>
                    <li>Call MSU: <span class="msu-phone"><strong>(517) 355-1855</strong></span></li>
                    <li>Visit: <strong><a href="http://msu.edu">msu.edu</a></strong></li>
                    <li>MSU is an affirmative-action, equal-opportunity employer.</li>
                  </ul>
                  <ul>
                    <li class="spartans-will">Spartans Will.</li>
                    <li>&#169; Michigan State University</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  computed: {
    routes () {
      return this.$router.options.routes.filter(route => {
        return !route.redirect
      })
    },
    additionalResources () {
      return this.$store.state.additionalResources
    }
  }
};
</script>

<style>
@import 'https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css';
</style>

<style type="text/css">

.fill-width {
  width: 100%;
}

/* #MSU Footer
================================================== */
#MSUstandardFooter {

}

#MSUstandardFooter .fourMSUcolumns {
  width: 221px;
  float: left;
  position: relative;
  margin-right: 18px;
}


#MSUstandardFooter .fourMSUcolumns img {
  width: 100%;
  margin-top: 13px;
}

#MSUstandardFooter .twelveMSUcolumns {
  width: 700px;
  float: left;
  position: relative;
  margin: 5px 0px 0px 0px;

}


#MSUstandardFooter #standard-footer-site-links ul {
  margin: 0px;
  padding: 0px;
  height: 26px;
  border-bottom: 1px dotted #808080;
  float: left;
  clear: right;
}

#MSUstandardFooter #standard-footer-site-links ul li {
  display: inline-block;
  padding-left: 4px;
  font-size: 12px;
}

#MSUstandardFooter #standard-footer-site-links ul li:first-child {
  padding: 0px;
}

#MSUstandardFooter #standard-footer-site-links ul li:nth-child(2) a {
  padding: 0px 8px 0px 8px;

}

#MSUstandardFooter #standard-footer-site-links ul li:last-child a {
  border-right: none;
}

#MSUstandardFooter #standard-footer-site-links ul li a {
  text-decoration: none;
  padding-right: 8px;

}

#MSUstandardFooter #standard-footer-site-links ul li a:hover {
  text-decoration: underline;
}

#MSUstandardFooter.light-background #standard-footer-site-links {
  color: #18453b;
}

#MSUstandardFooter.light-background #standard-footer-site-links a {
  color: #18453b;
  border-right: 1px solid #18453b;
}

#MSUstandardFooter.light-background #standard-footer-site-links ul li:nth-child(2) a {
  border-left: 1px solid #18354b;
}

#MSUstandardFooter.dark-background #standard-footer-site-links {
  color: #fff;
}


#MSUstandardFooter.dark-background #standard-footer-site-links ul li:nth-child(2) a {
  border-left: 1px solid #737373;
}

#MSUstandardFooter.dark-background #standard-footer-site-links a {
  color: #fff;
  border-right: 1px solid #737373;
}


#MSUstandardFooter #standard-footer-MSU-info {
  font-size: 9px;
  margin-top: 8px;
  padding-top: 0px;
  float: left;
  clear: right;
  display: block;
}

#MSUstandardFooter #standard-footer-MSU-info ul {
  margin: 0px;
  padding: 0px;
  height: 16px;
}

#MSUstandardFooter #standard-footer-MSU-info ul li {
  margin-bottom: 0px;
  line-height: 10px;
  display: inline;
  border-right: 1px solid #999;
  padding: 0px 8px 0px 4px;
}

#MSUstandardFooter #standard-footer-MSU-info ul li.spartans-will {
  text-transform: uppercase;
  font-weight: 600;
}


#MSUstandardFooter #standard-footer-MSU-info ul li:first-child {
  padding-left: 0px;
}

#MSUstandardFooter #standard-footer-MSU-info ul li:last-child {
  border-right: 0px;
}

#MSUstandardFooter #standard-footer-MSU-info ul li a {
  text-decoration: none;
}

#MSUstandardFooter #standard-footer-MSU-info ul li a:hover {
  text-decoration: underline;
}

#MSUstandardFooter.light-background #standard-footer-MSU-info {
  color: #18453b;
}

#MSUstandardFooter.light-background #standard-footer-MSU-info a, #MSUstandardFooter.light-background #standard-footer-MSU-info .msu-phone {
  color: #4c7e00;
}


#MSUstandardFooter.dark-background #standard-footer-MSU-info {
  color: #fff;
}

#MSUstandardFooter.dark-background #standard-footer-MSU-info a, #MSUstandardFooter.dark-background #standard-footer-MSU-info .msu-phone {
  color: #84d123;
}

#standard-footer-MSU-wordmark img.print-msuwordmark {
  display: none;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
