import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import {version} from '../../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        version: '',
        portalItem: {
            id: "71d3d1ce930b405db3cccacb658780c8",
            // autocastable to Portal
            portal: {
                url: "https://msusdm.maps.arcgis.com"
            }
        },
        additionalResources: [
            {
                title: 'USPS ADDRESSING STANDARDS',
                url: 'https://pe.usps.com/text/pub28/welcome.htm'
            },
            {
                title: 'UNIVERSITY SERVICES',
                url: 'https://usd.msu.edu/'
            },
            {
                title: 'CAMPUS MAPS',
                url: 'https://maps.msu.edu'
            },
            {
                title: 'MSU POLICE',
                url: 'https://police.msu.edu/'
            }
        ]
    },
    mutations: {
        initialiseStore(state) {
            if (localStorage.getItem('edu.msu.campusaddresses')) {
                let store = JSON.parse(localStorage.getItem('edu.msu.campusaddresses'))
                if (store.version == version){
                    this.replaceState(
                        Object.assign(state,store)
                    )
                } else {
                    state.version = version
                }
            }
        }
    },
    modules: {}
})
