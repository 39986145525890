import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#18453b',
        secondary: '#0db14b',
        error: '#b71c1c',
        success: '#0db14b',
        accent: '#008183'
      },
      dark: {
        primary: '#607d8b',
        secondary: '#009688',
        accent: '#cddc39',
        error: '#f44336',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#4caf50'
      }
    }
  }
})
