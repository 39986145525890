import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home-view" */ '../views/Home.vue'),
        meta: {
            title: 'HOME'
        }
    },

    {
        path: '/address-map',
        name: 'map',
        alias: '/map',
        component: () => import(/* webpackChunkName: "map-view" */ '../views/Map.vue'),
        meta: {
            title: 'ADDRESS MAP',
            gtm: 'address-map'
        }
    },

    {
        path: '/address-list',
        name: 'list',
        alias: '/list',
        component: () => import(/* webpackChunkName: "list-view" */ '../views/AddressList.vue'),
        meta: {
            title: 'ADDRESS LIST',
            gtm: 'address-list'
        }
    },

    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq-view" */ '../views/FAQ.vue'),
        meta: {
            title: 'FAQ',
            gtm: 'address-faqs'
        }
    },

    {
        path: '/instructions-and-examples',
        name: 'instructions',
        alias: '/instructions',
        component: () => import(/* webpackChunkName: "instructions-view" */ '../views/Instructions.vue'),
        meta: {
            title: 'INSTRUCTIONS AND EXAMPLES',
            gtm: 'address-instruction-and-examples'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    base: '/'
})

export default router
